import * as i1 from '@angular/cdk/overlay';
import { OverlayModule } from '@angular/cdk/overlay';
import * as i4 from '@angular/cdk/a11y';
import { A11yModule } from '@angular/cdk/a11y';
import * as i7 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Optional, Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import * as i2 from '@angular/cdk/scrolling';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import * as i5 from '@angular/cdk/bidi';
import * as i6 from '@angular/cdk/layout';
import { Breakpoints } from '@angular/cdk/layout';
import * as i3 from '@angular/cdk/platform';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { _MatTooltipBase, MAT_TOOLTIP_SCROLL_STRATEGY, MAT_TOOLTIP_DEFAULT_OPTIONS, _TooltipComponentBase, MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
const _c0 = ["tooltip"];
export { SCROLL_THROTTLE_MS as LEGACY_SCROLL_THROTTLE_MS, MAT_TOOLTIP_DEFAULT_OPTIONS as MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY as MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MAT_TOOLTIP_SCROLL_STRATEGY as MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY, MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY as MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY_FACTORY, MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER as MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, getMatTooltipInvalidPositionError as getMatLegacyTooltipInvalidPositionError } from '@angular/material/tooltip';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

/**
 * Directive that attaches a material design tooltip to the host element. Animates the showing and
 * hiding of a tooltip provided position (defaults to below the element).
 *
 * https://material.io/design/components/tooltips.html
 *
 * @deprecated Use `MatTooltip` from `@angular/material/tooltip` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyTooltip extends _MatTooltipBase {
  constructor(overlay, elementRef, scrollDispatcher, viewContainerRef, ngZone, platform, ariaDescriber, focusMonitor, scrollStrategy, dir, defaultOptions, _document) {
    super(overlay, elementRef, scrollDispatcher, viewContainerRef, ngZone, platform, ariaDescriber, focusMonitor, scrollStrategy, dir, defaultOptions, _document);
    this._tooltipComponent = LegacyTooltipComponent;
  }
  static {
    this.ɵfac = function MatLegacyTooltip_Factory(ɵt) {
      return new (ɵt || MatLegacyTooltip)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.ScrollDispatcher), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i3.Platform), i0.ɵɵdirectiveInject(i4.AriaDescriber), i0.ɵɵdirectiveInject(i4.FocusMonitor), i0.ɵɵdirectiveInject(MAT_TOOLTIP_SCROLL_STRATEGY), i0.ɵɵdirectiveInject(i5.Directionality, 8), i0.ɵɵdirectiveInject(MAT_TOOLTIP_DEFAULT_OPTIONS, 8), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatLegacyTooltip,
      selectors: [["", "matTooltip", ""]],
      hostAttrs: [1, "mat-tooltip-trigger"],
      hostVars: 2,
      hostBindings: function MatLegacyTooltip_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("mat-tooltip-disabled", ctx.disabled);
        }
      },
      exportAs: ["matTooltip"],
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyTooltip, [{
    type: Directive,
    args: [{
      selector: '[matTooltip]',
      exportAs: 'matTooltip',
      host: {
        'class': 'mat-tooltip-trigger',
        '[class.mat-tooltip-disabled]': 'disabled'
      }
    }]
  }], function () {
    return [{
      type: i1.Overlay
    }, {
      type: i0.ElementRef
    }, {
      type: i2.ScrollDispatcher
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }, {
      type: i3.Platform
    }, {
      type: i4.AriaDescriber
    }, {
      type: i4.FocusMonitor
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MAT_TOOLTIP_SCROLL_STRATEGY]
      }]
    }, {
      type: i5.Directionality,
      decorators: [{
        type: Optional
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_TOOLTIP_DEFAULT_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
/**
 * Internal component that wraps the tooltip's content.
 * @docs-private
 * @deprecated Use `TooltipComponent` from `@angular/material/tooltip` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class LegacyTooltipComponent extends _TooltipComponentBase {
  constructor(changeDetectorRef, breakpointObserver, animationMode) {
    super(changeDetectorRef, animationMode);
    this._showAnimation = 'mat-tooltip-show';
    this._hideAnimation = 'mat-tooltip-hide';
    this._isHandset = breakpointObserver.observe(Breakpoints.Handset);
  }
  static {
    this.ɵfac = function LegacyTooltipComponent_Factory(ɵt) {
      return new (ɵt || LegacyTooltipComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i6.BreakpointObserver), i0.ɵɵdirectiveInject(ANIMATION_MODULE_TYPE, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LegacyTooltipComponent,
      selectors: [["mat-tooltip-component"]],
      viewQuery: function LegacyTooltipComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._tooltip = _t.first);
        }
      },
      hostAttrs: ["aria-hidden", "true"],
      hostVars: 3,
      hostBindings: function LegacyTooltipComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseleave", function LegacyTooltipComponent_mouseleave_HostBindingHandler($event) {
            return ctx._handleMouseLeave($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("mat-id-collision", null);
          i0.ɵɵstyleProp("zoom", ctx.isVisible() ? 1 : null);
        }
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 6,
      consts: [["tooltip", ""], [1, "mat-tooltip", 3, "animationend", "ngClass"]],
      template: function LegacyTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵlistener("animationend", function LegacyTooltipComponent_Template_div_animationend_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx._handleAnimationEnd($event));
          });
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_1_0;
          i0.ɵɵclassProp("mat-tooltip-handset", (tmp_1_0 = i0.ɵɵpipeBind1(2, 4, ctx._isHandset)) == null ? null : tmp_1_0.matches);
          i0.ɵɵproperty("ngClass", ctx.tooltipClass);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate(ctx.message);
        }
      },
      dependencies: [i7.NgClass, i7.AsyncPipe],
      styles: [".mat-tooltip{color:#fff;border-radius:4px;margin:14px;max-width:250px;padding-left:8px;padding-right:8px;overflow:hidden;text-overflow:ellipsis;transform:scale(0)}.mat-tooltip._mat-animation-noopable{animation:none;transform:scale(1)}.cdk-high-contrast-active .mat-tooltip{outline:solid 1px}.mat-tooltip-handset{margin:24px;padding-left:16px;padding-right:16px}.mat-tooltip-panel-non-interactive{pointer-events:none}@keyframes mat-tooltip-show{0%{opacity:0;transform:scale(0)}50%{opacity:.5;transform:scale(0.99)}100%{opacity:1;transform:scale(1)}}@keyframes mat-tooltip-hide{0%{opacity:1;transform:scale(1)}100%{opacity:0;transform:scale(1)}}.mat-tooltip-show{animation:mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards}.mat-tooltip-hide{animation:mat-tooltip-hide 100ms cubic-bezier(0, 0, 0.2, 1) forwards}"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LegacyTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'mat-tooltip-component',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        // Forces the element to have a layout in IE and Edge. This fixes issues where the element
        // won't be rendered if the animations are disabled or there is no web animations polyfill.
        '[style.zoom]': 'isVisible() ? 1 : null',
        '(mouseleave)': '_handleMouseLeave($event)',
        'aria-hidden': 'true',
        // This binding is used to ensure that the component
        // ID doesn't clash with the `TooltipComponent`.
        '[attr.mat-id-collision]': 'null'
      },
      template: "<div #tooltip\n     class=\"mat-tooltip\"\n     (animationend)=\"_handleAnimationEnd($event)\"\n     [ngClass]=\"tooltipClass\"\n     [class.mat-tooltip-handset]=\"(_isHandset | async)?.matches\">{{message}}</div>\n",
      styles: [".mat-tooltip{color:#fff;border-radius:4px;margin:14px;max-width:250px;padding-left:8px;padding-right:8px;overflow:hidden;text-overflow:ellipsis;transform:scale(0)}.mat-tooltip._mat-animation-noopable{animation:none;transform:scale(1)}.cdk-high-contrast-active .mat-tooltip{outline:solid 1px}.mat-tooltip-handset{margin:24px;padding-left:16px;padding-right:16px}.mat-tooltip-panel-non-interactive{pointer-events:none}@keyframes mat-tooltip-show{0%{opacity:0;transform:scale(0)}50%{opacity:.5;transform:scale(0.99)}100%{opacity:1;transform:scale(1)}}@keyframes mat-tooltip-hide{0%{opacity:1;transform:scale(1)}100%{opacity:0;transform:scale(1)}}.mat-tooltip-show{animation:mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards}.mat-tooltip-hide{animation:mat-tooltip-hide 100ms cubic-bezier(0, 0, 0.2, 1) forwards}"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i6.BreakpointObserver
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [ANIMATION_MODULE_TYPE]
      }]
    }];
  }, {
    _tooltip: [{
      type: ViewChild,
      args: ['tooltip', {
        // Use a static query here since we interact directly with
        // the DOM which can happen before `ngAfterViewInit`.
        static: true
      }]
    }]
  });
})();

/**
 * @deprecated Use `MatTooltipModule` from `@angular/material/tooltip` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyTooltipModule {
  static {
    this.ɵfac = function MatLegacyTooltipModule_Factory(ɵt) {
      return new (ɵt || MatLegacyTooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatLegacyTooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
      imports: [A11yModule, CommonModule, OverlayModule, MatCommonModule, MatCommonModule, CdkScrollableModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [A11yModule, CommonModule, OverlayModule, MatCommonModule],
      exports: [MatLegacyTooltip, LegacyTooltipComponent, MatCommonModule, CdkScrollableModule],
      declarations: [MatLegacyTooltip, LegacyTooltipComponent],
      providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Animations used by MatTooltip.
 * @docs-private
 * @deprecated Use `matTooltipAnimations` from `@angular/material/tooltip` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
const matLegacyTooltipAnimations = {
  /** Animation that transitions a tooltip in and out. */
  tooltipState: trigger('state', [state('initial, void, hidden', style({
    opacity: 0,
    transform: 'scale(0)'
  })), state('visible', style({
    transform: 'scale(1)'
  })), transition('* => visible', animate('200ms cubic-bezier(0, 0, 0.2, 1)', keyframes([style({
    opacity: 0,
    transform: 'scale(0)',
    offset: 0
  }), style({
    opacity: 0.5,
    transform: 'scale(0.99)',
    offset: 0.5
  }), style({
    opacity: 1,
    transform: 'scale(1)',
    offset: 1
  })]))), transition('* => hidden', animate('100ms cubic-bezier(0, 0, 0.2, 1)', style({
    opacity: 0
  })))])
};

/**
 * Generated bundle index. Do not edit.
 */

export { LegacyTooltipComponent, MatLegacyTooltip, MatLegacyTooltipModule, matLegacyTooltipAnimations };
