import * as i1$2 from '@angular/cdk/overlay';
import { OverlayModule } from '@angular/cdk/overlay';
import * as i1$1 from '@angular/cdk/portal';
import { PortalModule } from '@angular/cdk/portal';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, NgModule, Injectable, Optional, SkipSelf } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import * as i3 from '@angular/material/legacy-button';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import * as i1 from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA, _MatSnackBarContainerBase, matSnackBarAnimations, _MatSnackBarBase, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
function LegacySimpleSnackBar_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "button", 3);
    i0.ɵɵlistener("click", function LegacySimpleSnackBar_div_2_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.action());
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.data.action);
  }
}
function MatLegacySnackBarContainer_ng_template_1_Template(rf, ctx) {}
export { MAT_SNACK_BAR_DATA as MAT_LEGACY_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS as MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS, MAT_SNACK_BAR_DEFAULT_OPTIONS_FACTORY as MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS_FACTORY, MatSnackBarConfig as MatLegacySnackBarConfig, MatSnackBarRef as MatLegacySnackBarRef, _MatSnackBarBase as _MatLegacySnackBarBase, _MatSnackBarContainerBase as _MatLegacySnackBarContainerBase, matSnackBarAnimations as matLegacySnackBarAnimations } from '@angular/material/snack-bar';
import * as i2$1 from '@angular/cdk/a11y';
import * as i3$1 from '@angular/cdk/layout';

/**
 * A component used to open as the default snack bar, matching material spec.
 * This should only be used internally by the snack bar service.
 * @deprecated Use `SimpleSnackBar` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class LegacySimpleSnackBar {
  constructor(snackBarRef, data) {
    this.snackBarRef = snackBarRef;
    this.data = data;
  }
  /** Performs the action on the snack bar. */
  action() {
    this.snackBarRef.dismissWithAction();
  }
  /** If the action button should be shown. */
  get hasAction() {
    return !!this.data.action;
  }
  static {
    this.ɵfac = function LegacySimpleSnackBar_Factory(ɵt) {
      return new (ɵt || LegacySimpleSnackBar)(i0.ɵɵdirectiveInject(i1.MatSnackBarRef), i0.ɵɵdirectiveInject(MAT_SNACK_BAR_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LegacySimpleSnackBar,
      selectors: [["simple-snack-bar"]],
      hostAttrs: [1, "mat-simple-snackbar"],
      decls: 3,
      vars: 2,
      consts: [[1, "mat-simple-snack-bar-content"], ["class", "mat-simple-snackbar-action", 4, "ngIf"], [1, "mat-simple-snackbar-action"], ["mat-button", "", 3, "click"]],
      template: function LegacySimpleSnackBar_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, LegacySimpleSnackBar_div_2_Template, 3, 1, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.data.message);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.hasAction);
        }
      },
      dependencies: [i2.NgIf, i3.MatLegacyButton],
      styles: [".mat-simple-snackbar{display:flex;justify-content:space-between;align-items:center;line-height:20px;opacity:1}.mat-simple-snackbar-action{flex-shrink:0;margin:-8px -8px -8px 8px}.mat-simple-snackbar-action button{max-height:36px;min-width:0}[dir=rtl] .mat-simple-snackbar-action{margin-left:-8px;margin-right:8px}.mat-simple-snack-bar-content{overflow:hidden;text-overflow:ellipsis}"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LegacySimpleSnackBar, [{
    type: Component,
    args: [{
      selector: 'simple-snack-bar',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'mat-simple-snackbar'
      },
      template: "<span class=\"mat-simple-snack-bar-content\">{{data.message}}</span>\n<div class=\"mat-simple-snackbar-action\"  *ngIf=\"hasAction\">\n  <button mat-button (click)=\"action()\">{{data.action}}</button>\n</div>\n",
      styles: [".mat-simple-snackbar{display:flex;justify-content:space-between;align-items:center;line-height:20px;opacity:1}.mat-simple-snackbar-action{flex-shrink:0;margin:-8px -8px -8px 8px}.mat-simple-snackbar-action button{max-height:36px;min-width:0}[dir=rtl] .mat-simple-snackbar-action{margin-left:-8px;margin-right:8px}.mat-simple-snack-bar-content{overflow:hidden;text-overflow:ellipsis}"]
    }]
  }], function () {
    return [{
      type: i1.MatSnackBarRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MAT_SNACK_BAR_DATA]
      }]
    }];
  }, null);
})();

/**
 * Internal component that wraps user-provided snack bar content.
 * @docs-private
 * @deprecated Use `MatSnackBarContainer` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBarContainer extends _MatSnackBarContainerBase {
  _afterPortalAttached() {
    super._afterPortalAttached();
    if (this.snackBarConfig.horizontalPosition === 'center') {
      this._elementRef.nativeElement.classList.add('mat-snack-bar-center');
    }
    if (this.snackBarConfig.verticalPosition === 'top') {
      this._elementRef.nativeElement.classList.add('mat-snack-bar-top');
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacySnackBarContainer_BaseFactory;
      return function MatLegacySnackBarContainer_Factory(ɵt) {
        return (ɵMatLegacySnackBarContainer_BaseFactory || (ɵMatLegacySnackBarContainer_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacySnackBarContainer)))(ɵt || MatLegacySnackBarContainer);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacySnackBarContainer,
      selectors: [["snack-bar-container"]],
      hostAttrs: [1, "mat-snack-bar-container"],
      hostVars: 1,
      hostBindings: function MatLegacySnackBarContainer_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵsyntheticHostListener("@state.done", function MatLegacySnackBarContainer_animation_state_done_HostBindingHandler($event) {
            return ctx.onAnimationEnd($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@state", ctx._animationState);
        }
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 3,
      vars: 3,
      consts: [["aria-hidden", "true"], ["cdkPortalOutlet", ""]],
      template: function MatLegacySnackBarContainer_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, MatLegacySnackBarContainer_ng_template_1_Template, 0, 0, "ng-template", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(2, "div");
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵattribute("aria-live", ctx._live)("role", ctx._role)("id", ctx._liveElementId);
        }
      },
      dependencies: [i1$1.CdkPortalOutlet],
      styles: [".mat-snack-bar-container{border-radius:4px;box-sizing:border-box;display:block;margin:24px;max-width:33vw;min-width:344px;padding:14px 16px;min-height:48px;transform-origin:center}.cdk-high-contrast-active .mat-snack-bar-container{border:solid 1px}.mat-snack-bar-handset{width:100%}.mat-snack-bar-handset .mat-snack-bar-container{margin:8px;max-width:100%;min-width:0;width:100%}"],
      encapsulation: 2,
      data: {
        animation: [matSnackBarAnimations.snackBarState]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacySnackBarContainer, [{
    type: Component,
    args: [{
      selector: 'snack-bar-container',
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None,
      animations: [matSnackBarAnimations.snackBarState],
      host: {
        'class': 'mat-snack-bar-container',
        '[@state]': '_animationState',
        '(@state.done)': 'onAnimationEnd($event)'
      },
      template: "<!-- Initially holds the snack bar content, will be empty after announcing to screen readers. -->\n<div aria-hidden=\"true\">\n  <ng-template cdkPortalOutlet></ng-template>\n</div>\n\n<!-- Will receive the snack bar content from the non-live div, move will happen a short delay after opening -->\n<div [attr.aria-live]=\"_live\" [attr.role]=\"_role\" [attr.id]=\"_liveElementId\"></div>\n",
      styles: [".mat-snack-bar-container{border-radius:4px;box-sizing:border-box;display:block;margin:24px;max-width:33vw;min-width:344px;padding:14px 16px;min-height:48px;transform-origin:center}.cdk-high-contrast-active .mat-snack-bar-container{border:solid 1px}.mat-snack-bar-handset{width:100%}.mat-snack-bar-handset .mat-snack-bar-container{margin:8px;max-width:100%;min-width:0;width:100%}"]
    }]
  }], null, null);
})();

/**
 * @deprecated Use `MatSnackBarModule` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBarModule {
  static {
    this.ɵfac = function MatLegacySnackBarModule_Factory(ɵt) {
      return new (ɵt || MatLegacySnackBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatLegacySnackBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [OverlayModule, PortalModule, CommonModule, MatLegacyButtonModule, MatCommonModule, MatCommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacySnackBarModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, PortalModule, CommonModule, MatLegacyButtonModule, MatCommonModule],
      exports: [MatLegacySnackBarContainer, MatCommonModule],
      declarations: [MatLegacySnackBarContainer, LegacySimpleSnackBar]
    }]
  }], null, null);
})();

/**
 * Service to dispatch Material Design snack bar messages.
 * @deprecated Use `MatSnackBar` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBar extends _MatSnackBarBase {
  constructor(overlay, live, injector, breakpointObserver, parentSnackBar, defaultConfig) {
    super(overlay, live, injector, breakpointObserver, parentSnackBar, defaultConfig);
    this.simpleSnackBarComponent = LegacySimpleSnackBar;
    this.snackBarContainerComponent = MatLegacySnackBarContainer;
    this.handsetCssClass = 'mat-snack-bar-handset';
  }
  static {
    this.ɵfac = function MatLegacySnackBar_Factory(ɵt) {
      return new (ɵt || MatLegacySnackBar)(i0.ɵɵinject(i1$2.Overlay), i0.ɵɵinject(i2$1.LiveAnnouncer), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i3$1.BreakpointObserver), i0.ɵɵinject(MatLegacySnackBar, 12), i0.ɵɵinject(MAT_SNACK_BAR_DEFAULT_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MatLegacySnackBar,
      factory: MatLegacySnackBar.ɵfac,
      providedIn: MatLegacySnackBarModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacySnackBar, [{
    type: Injectable,
    args: [{
      providedIn: MatLegacySnackBarModule
    }]
  }], function () {
    return [{
      type: i1$2.Overlay
    }, {
      type: i2$1.LiveAnnouncer
    }, {
      type: i0.Injector
    }, {
      type: i3$1.BreakpointObserver
    }, {
      type: MatLegacySnackBar,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: i1.MatSnackBarConfig,
      decorators: [{
        type: Inject,
        args: [MAT_SNACK_BAR_DEFAULT_OPTIONS]
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LegacySimpleSnackBar, MatLegacySnackBar, MatLegacySnackBarContainer, MatLegacySnackBarModule };
